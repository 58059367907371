import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/blogPost_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "there-is-freedom-in-learning",
      "style": {
        "position": "relative"
      }
    }}>{`There is freedom in learning`}<a parentName="h1" {...{
        "href": "#there-is-freedom-in-learning",
        "aria-label": "there is freedom in learning permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Not all teachers are leaders.`}<br parentName="p"></br>{`
`}{`But all leaders are teachers.  `}</p>
    <p>{`Let me lead you through a desert.`}<br parentName="p"></br>{`
`}{`Here your mouth will turn dry`}<br parentName="p"></br>{`
`}{`And your throat will learn to thirst for knowledge.`}<br parentName="p"></br>{`
`}{`It will be knowledge unknown to us both.  `}</p>
    <p>{`Not all teachers are mothers.`}<br parentName="p"></br>{`
`}{`But all mothers are teachers.  `}</p>
    <p>{`Let me carry you towards thirst`}<br parentName="p"></br>{`
`}{`As you live in gluttony.`}<br parentName="p"></br>{`
`}{`So that you may hunger no more`}<br parentName="p"></br>{`
`}{`And you may rest, as your hands grow idle`}<br parentName="p"></br>{`
`}{`May your mind and heart listen more deeply.  `}</p>
    <p>{`Then will you hear me say: I love you.`}<br parentName="p"></br>{`
`}{`I love you enough to nurture your growth.`}<br parentName="p"></br>{`
`}{`A growth towards the answers you seek`}<br parentName="p"></br>{`
`}{`Hidden beneath the realistic fiction you preach`}<br parentName="p"></br>{`
`}{`You will find a seed of truth`}<br parentName="p"></br>{`
`}{`Covered in a kernel of meaning`}<br parentName="p"></br>{`
`}{`Once that freedom has consumed the spaces you locked away`}<br parentName="p"></br>{`
`}{`Your throat will shudder as you scream, I love you.  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      